<template>
  <div id="updated-roster">
    <b-card>
      <FullCalendar ref="FullCalendar" :options="calendarOptions">
        <template v-slot:eventContent="arg">
          <b>{{ arg.timeText }}</b>
          <i>{{ arg.event.title }}</i>
        </template>
      </FullCalendar>
    </b-card>
    <b-modal
      id="modal-prevent-closing"
      ref="modal"
      :title="roster.roster_id ? 'Update Roster' : 'Create New Roster'"
      size="lg"
      centered
      @show="resetModal"
      @hidden="resetModal"
      @ok="handleOk"
      :ok-title="roster.roster_id ? 'Update' : 'Save'"
      :ok-disabled="
        ableToCreateRoster === false
          ? true
          : roster.status == 1 || roster.roster_id
          ? !$can('update', 'Roster')
          : !$can('create', 'Roster')
      "
    >
      <validation-observer ref="simpleRules">
        <form ref="form">
          <p class="id" v-if="employeeAvailable.start_time">
            This employee is available in between
            {{
              new Date(
                roster.date + " " + employeeAvailable.start_time
              ).toLocaleTimeString()
            }}
            to
            {{
              new Date(
                roster.date + " " + employeeAvailable.end_time
              ).toLocaleTimeString()
            }}
            on {{ formateDate(roster.date) }}
          </p>
          <p v-else class="id">
            This Employee is not available on {{ formateDate(roster.date) }}
          </p>
          <div
            v-if="
              roster.roster_id
                ? $can('update', 'Roster')
                : $can('create', 'Roster')
            "
            class="row"
          >
            <div
              class="col-md-6"
              v-if="logedinUser.roles[0].name === 'superadmin'"
            >
              <validation-provider
                #default="{ errors }"
                name="Company"
                rules="required"
              >
                <label>Company <span class="text-danger">*</span></label>
                <b-form-group
                  label-for="name-input"
                  invalid-feedback="company is required"
                  :state="nameState"
                >
                  <v-select
                    v-model="roster.company"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="companies"
                    class="bg-light"
                    placeholder="Select company"
                    @input="getCustomers"
                    disabled
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <!-- v-if="clientPreference !== 0" -->
            <div v-if="logedinUser.roles[0].name === 'superadmin'" class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Client"
                rules="required"
              >
                <label>Client <span class="text-danger">*</span></label>
                <b-form-group
                  label-for="name-input"
                  invalid-feedback="Duty is required"
                  :state="nameState"
                >
                  <v-select
                    v-model="roster.customer"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="customers"
                    class="bg-light"
                    placeholder="Select customer"
                    disabled
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Location"
                rules="required"
              >
                <label>Location <span class="text-danger">*</span></label>
                <b-form-group
                  label-for="name-input"
                  invalid-feedback="Location is required"
                  :state="nameState"
                >
                  <v-select
                    v-model="roster.location"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="locations"
                    class="bg-light"
                    placeholder="Select Location"
                    :disabled="roster.status == 1"
                    @input="getSubLocations(roster.location.id)"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <b-form-group
                label="Sub Location"
                label-for="name-input"
                invalid-feedback="Sub Location is required"
                :state="nameState"
              >
                <v-select
                  v-model="roster.sub_location"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="subLocations"
                  class="bg-light"
                  placeholder="Select Sub Location"
                  :disabled="roster.status == 1"
                />
              </b-form-group>
            </div>
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Job Role"
                rules="required"
              >
                <label>Job Role <span class="text-danger">*</span></label>
                <b-form-group
                  label-for="name-input"
                  invalid-feedback="Job Role is required"
                  :state="nameState"
                >
                  <v-select
                    v-model="roster.duty"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    label="name"
                    :options="duties"
                    class="bg-light"
                    placeholder="Select Job Role"
                  />
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <b-form-group
                label="Shift"
                label-for="name-input"
                invalid-feedback="Shift is required"
                :state="nameState"
              >
                <v-select
                  @input="setShiftTime(roster.shift)"
                  v-model="roster.shift"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="name"
                  :options="shifts"
                  class="bg-light"
                  placeholder="Select Shift"
                />
              </b-form-group>
            </div>
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="Start Time"
                rules="required"
              >
                <label>Start Time <span class="text-danger">*</span></label>
                <b-form-group
                  label-for="name-input"
                  invalid-feedback="Start time is required"
                  :state="nameState"
                >
                  <b-form-timepicker
                    class="bg-light"
                    v-model="roster.start_time"
                    locale="en"
                    now-button
                    label-close-button="Set"
                  ></b-form-timepicker>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6">
              <validation-provider
                #default="{ errors }"
                name="End Time"
                rules="required"
              >
                <label>End Time <span class="text-danger">*</span></label>
                <b-form-group
                  label-for="name-input"
                  invalid-feedback="End time is required"
                  :state="nameState"
                >
                  <b-form-timepicker
                    class="bg-light"
                    v-model="roster.end_time"
                    locale="en"
                    now-button
                    label-close-button="Set"
                  ></b-form-timepicker>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-6 mb-5">
              <b-form-group label="Change Status" label-for="name-input">
                <v-select
                  v-model="roster.status"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="text"
                  :options="statuses"
                  class="bg-light"
                  placeholder="Select status"
                />
              </b-form-group>
            </div>
            <div class="col-md-6 mb-5">
              <validation-provider
                #default="{ errors }"
                name="Update Note"
                rules="required"
              >
                <label>Update Note <span class="text-danger">*</span></label>
                <b-form-group
                  label-for="name-input"
                  invalid-feedback="Update note is required"
                  :state="nameState"
                  v-if="roster.roster_id"
                >
                  <b-form-textarea
                    class="bg-light"
                    v-model="roster.note"
                    placeholder="Please enter update note"
                  ></b-form-textarea>
                </b-form-group>
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </div>
            <div class="col-md-12">
              <div v-for="(brk, index) in breaks" :key="index" class="row">
                <div class="col-4">
                  <label>Break Name <span class="text-danger">*</span></label>
                  <b-form-group label-for="name-input">
                    <validation-provider
                      #default="{ errors }"
                      :name="`Break ${index + 1} Name`"
                      rules="required"
                    >
                      <b-form-input
                        class="bg-light data-text"
                        v-model="brk.name"
                        placeholder="Please enter shift name"
                        disabled
                      ></b-form-input>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <label
                    >Break Start Time <span class="text-danger">*</span></label
                  >
                  <b-form-group label-for="name-input">
                    <validation-provider
                      #default="{ errors }"
                      :name="`Break ${index + 1} Start Time}`"
                      rules="required"
                    >
                      <b-form-timepicker
                        class="bg-light"
                        v-model="brk.start_time"
                        locale="en"
                        now-button
                        label-close-button="Set"
                      ></b-form-timepicker>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
                <div class="col-4">
                  <label
                    >Break End Time <span class="text-danger">*</span></label
                  >
                  <b-form-group label-for="name-input">
                    <validation-provider
                      #default="{ errors }"
                      :name="`Break ${index + 1} End Time}`"
                      rules="required"
                    >
                      <b-form-timepicker
                        class="bg-light"
                        v-model="brk.end_time"
                        locale="en"
                        now-button
                        label-close-button="Set"
                      ></b-form-timepicker>
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </div>
              </div>
            </div>
            <div class="col-12">
              <b-form-checkbox
                id="repeat"
                v-model="repeat"
                name="repeat"
                :value="true"
                :unchecked-value="false"
              >
                Repeat
              </b-form-checkbox>
              <div class="card p-2 mt-2" v-if="repeat === true">
                <b-form-checkbox-group
                  id="checkbox-group-1"
                  v-model="roster.selectedRepeatableDays"
                  :options="days"
                  name="flavour-1"
                ></b-form-checkbox-group>
                <b-form-group class="mt-2" label="Repeat Type">
                  <div class="d-flex flex-row justify-content-start">
                    <div>
                      <b-form-radio v-model="until" name="weekly" value="1"
                        >Weekly</b-form-radio
                      >
                    </div>
                    <div class="ml-2">
                      <b-form-radio v-model="until" name="until" value="0"
                        >Until</b-form-radio
                      >
                    </div>
                  </div>

                  <div class="row" v-if="until == 0">
                    <div class="col-6">
                      <validation-provider
                        v-if="until == 0"
                        #default="{ errors }"
                        name="Repeat Until"
                        rules="required"
                      >
                        <b-form-group class="mt-2" label="Repeat Until">
                          <b-form-datepicker
                            v-model="roster.until"
                            @input="untilDateValidation(roster.until)"
                            locale="en"
                          />
                          <p class="text-danger" v-if="untilDate != ''">
                            {{ untilDate }}
                          </p>
                        </b-form-group>
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </div>
                  </div>
                </b-form-group>
              </div>
            </div>
          </div>
          <div v-else>
            <h4 v-if="roster.roster_id" class="my-2">
              You do not have permission to edit this roster!
            </h4>
            <h4 v-else class="my-2">
              You do not have permission to create this roster!
            </h4>
          </div>
        </form>
      </validation-observer>
    </b-modal>
  </div>
</template>
<script>
import store from "@/store";
import { getLogedInUser, getToken } from "@/utils/auth";
import { toast } from "@/utils/toast";
import { timeValidatorBetween, validateTimes } from "@/utils/validate";
import { VUE_APP_BASE_URL } from "@/utils/var";
import {
  default as dayGridMonth,
  default as dayGridPlugin,
} from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import resourceTimelinePlugin from "@fullcalendar/resource-timeline";
import FullCalendar from "@fullcalendar/vue";
import { email, required } from "@validations";
import {
  BButton,
  BCard,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BFormTimepicker,
  BFormCheckbox,
  BFormCheckboxGroup,
  BFormRadio,
  BFormDatepicker,
} from "bootstrap-vue";
import moment from "moment";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import vSelect from "vue-select";
export default {
  components: {
    BCard,
    BFormGroup,
    BFormInput,
    BFormTimepicker,
    BButton,
    BFormTextarea,
    FullCalendar, // make the <FullCalendar> tag available
    vSelect,
    ValidationObserver,
    ValidationProvider,
    BFormCheckbox,
    BFormCheckboxGroup,
    BFormRadio,
    BFormDatepicker,
  },
  props: {
    filterData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      email,
      required,
      clicked: "edit",
      duties: [],
      customers: [],
      companies: [],
      shifts: [],
      locations: [],
      subLocations: [],
      employeeAvailable: {},
      employeeExistingRosters: [],
      breaks: [],
      employeePassportValid: "",
      passportValidDays: 0,
      drivingValidDays: 0,
      visaValidDays: 0,
      certificateValidDays: 0,
      ableToCreateRoster: true,
      logedinUser: JSON.parse(getLogedInUser()),
      query: {
        role: "admin",
        company: null,
        customer: null,
        user_id: null,
        customer_id: null,
      },
      roster: {
        company: null,
        duty: null,
        shift: null,
        customer: null,
        customer_id: "",
        user_id: "",
        duty_id: "",
        start_time: "",
        end_time: "",
        date: "",
        employee_id: "",
        shift_id: "",
        note: "",
        status: null,
        location: null,
        sub_location: null,
        location_id: "",
        sub_location_id: "",
        // here tuhinjamal added data to integrate repeataion on templete
        selectedRepeatableDays: [],
        until: "",
        untilDate: "",
        // here tuhinjamal ends add data to integrate repeataion on templete
      },
      publishData: {
        status: 1,
        id: [],
      },
      statuses: [
        { value: 0, text: "Unpublish" },
        { value: 1, text: "Publish" },
        { value: 2, text: "Accept" },
        { value: 3, text: "Decline" },
      ],
      nameState: null,
      calendarOptions: {
        plugins: [
          resourceTimelinePlugin,
          interactionPlugin,
          dayGridPlugin,
          dayGridMonth,
        ],
        headerToolbar: {
          left: "prev,next today",
          center: "title",
          right:
            "resourceTimelineTenDay resourceTimelineFourtenDay resourceTimelineTwentyOneDay resourceTimelineTwentyEightDay",
        },
        initialView: "resourceTimelineTenDay",
        aspectRatio: 1.5,
        resourceAreaColumns: [
          {
            field: "title",
            headerContent: "Employee List",
          },
        ],
        eventTimeFormat: {
          date: "numeric",
          hour: "2-digit",
          minute: "2-digit",
        },
        views: {
          resourceTimelineTenDay: {
            type: "resourceTimeline",
            duration: { week: 1 },
            dateIncrement: { weeks: 1 },
            buttonText: "7 days",
            slotDuration: { days: 1 },
          },
          resourceTimelineFourtenDay: {
            type: "resourceTimeline",
            duration: { week: 2 },
            dateIncrement: { weeks: 2 },
            buttonText: "2 Week",
            slotDuration: { days: 1 },
          },
          resourceTimelineTwentyOneDay: {
            type: "resourceTimeline",
            duration: { week: 3 },
            dateIncrement: { weeks: 3 },
            buttonText: "3 Week",
            slotDuration: { days: 1 },
          },
          resourceTimelineTwentyEightDay: {
            type: "resourceTimeline",
            duration: { week: 4 },
            dateIncrement: { weeks: 4 },
            buttonText: "4 Week",
            slotDuration: { days: 1 },
          },
        },
        slotLabelFormat: [
          { weekday: "short", year: "numeric", month: "short", day: "numeric" },
        ],
        slotLabelInterval: { days: 1 },
        eventClick(info) {
          let self = this.el.__vue__;
          if (window.clickedOn == "edit") {
            self.$bvModal.show("modal-prevent-closing");
            // assign event to modal
            window.clickedOn = "";
          }
          if (window.clickedOn == "publish") {
            self.$bvModal.show("modal-1");
            window.clickedOn = "";
          }
          let startStr = new Date(info.event.startStr).toTimeString();
          let endStr = new Date(info.event.endStr).toTimeString();

          if (info.event.title == "Add Roster") {
            if (!self.$parent.ValidateDate(info.event.startStr)) {
              self.$parent.ableToCreateRoster = false;
            } else {
              self.$parent.roster.date = info.event.startStr;
              self.$parent.roster.employee_id = info.event._def.resourceIds[0];
              self.$parent.roster.company = self.$parent.filterData.company;
              self.$parent.roster.customer = self.$parent.filterData.customer;
            }            
          } else {
            self.$parent.roster.date = info.event.startStr.substring(
              0,
              info.event.startStr.indexOf("T")
            );
            self.$parent.roster.employee_id = info.event.id;
          }

          if (info.event.title !== "Add Roster") {
            self.$parent.roster.company = self.$parent.companies.find(
              (company) => company.id == info.event.extendedProps.user_id
            );
          }

          if (info.event.extendedProps.user_id) {
            self.$parent.query.user_id = info.event.extendedProps.user_id;
            self.$parent.getCustomers();
          }

          self.$parent.roster.roster_id = info.event.extendedProps.roster_id;
          self.$parent.roster.duty = info.event.extendedProps.duty;
          self.$parent.roster.shift = info.event.extendedProps.shift;
          self.$parent.roster.location = info.event.extendedProps.location;
          self.$parent.breaks = info.event.extendedProps.breaks;
          self.$parent.roster.location_id =
            info.event.extendedProps.location?.id;
          self.$parent.roster.sub_location =
            info.event.extendedProps.sub_location;
          self.$parent.roster.sub_location_id =
            info.event.extendedProps.sub_location?.id;
          if (self.$parent.roster.location_id) {
            self.$parent.getSubLocations(self.$parent.roster.location_id);
          }
          self.$parent.roster.status =
            info.event.extendedProps.e_status === 2 &&
            info.event.extendedProps.status === 1
              ? self.$parent.statuses[2]
              : info.event.extendedProps.e_status === 3 &&
                info.event.extendedProps.status === 1
              ? self.$parent.statuses[3]
              : info.event.extendedProps.e_status === 0 &&
                info.event.extendedProps.status === 1
              ? self.$parent.statuses[1]
              : self.$parent.statuses[0];
          self.$parent.roster.note = info.event.extendedProps.note
            ? info.event.extendedProps.note
            : "";

          if (info.event.title !== "Add Roster") {
            // setTimeout(() => {
            self.$parent.roster.customer = self.$parent.customers.find(
              (customer) => customer.id == info.event.extendedProps.customer
            );
            // }, 500);
          }

          self.$parent.roster.start_time = startStr.substring(
            0,
            startStr.indexOf(" GMT")
          );
          self.$parent.roster.end_time = endStr.substring(
            0,
            endStr.indexOf(" GMT")
          );
          self.$parent.checkEmployeeAvailability();
        },
        dateClick(info) {
          // get vue instance
          // let self = this.el.__vue__;
          // self.$bvModal.show("modal-prevent-closing");
          // self.$parent.roster.date = info.dateStr;
          // self.$parent.roster.employee_id = info.resource.id;
        },
        events: {
          url: `${VUE_APP_BASE_URL}/roster/event`,
          method: "GET",
          extraParams: {
            type: "event",
            Authorization: `Bearer ${getToken()}`,
            status: 1,
            user_id: "",
            customer_id: "",
            // sector: 0,
            // 'role': 'customer',
          },
        },
        resources: {
          url: `${VUE_APP_BASE_URL}/roster/resource`,
          method: "GET",
          extraParams: {
            Authorization: `Bearer ${getToken()}`,
            //'role': 'customer',
            sector: "",
          },
        },
        dragScroll: true,
        editable: true,
        //update event
        eventDrop: async function (info) {
          let self = this.el.__vue__;
          let date = info.event.startStr.substring(
            0,
            info.event.startStr.indexOf("T")
          );
          if (!self.$parent.ValidateDate(info.event.startStr)) {
            self.$parent.ableToCreateRoster = false;
            self.$parent.showToastNotification("drag");
          } else {
            let employee_id = info.newResource
              ? info.newResource.id
              : info.event.id;
            self.$parent.roster.date = date;
            self.$parent.roster.employee_id = employee_id;
            self.$parent.roster.roster_id = info.event.extendedProps.roster_id;
            self.$parent.roster.duty = info.event.extendedProps.duty;
            self.$parent.roster.shift = info.event.extendedProps.shift;
            self.$parent.roster.status = info.event.extendedProps.status;
            self.$parent.roster.e_status = info.event.extendedProps.e_status;
            self.$parent.roster.location = info.event.extendedProps.location;
            self.$parent.roster.location_id =
              info.event.extendedProps.location?.id;
            self.$parent.roster.sub_location =
              info.event.extendedProps.sub_location;
            self.$parent.roster.sub_location_id =
              info.event.extendedProps.sub_location?.id;
            self.$parent.roster.note = info.event.extendedProps.note
              ? info.event.extendedProps.note
              : "";
            self.$parent.roster.start_time = moment(info.event.startStr).format(
              "HH:mm"
            );
            self.$parent.roster.end_time = moment(info.event.endStr).format(
              "HH:mm"
            );
            self.$parent.roster.customer = self.$parent.customers.find(
              (customer) => customer.id == info.event.extendedProps.customer
            );
            self.$parent.roster.company = self.$parent.companies.find(
              (company) => company.id == info.event.extendedProps.user_id
            );
            await self.$parent.checkEmployeeAvailability("drag");
            self.$parent.updateRoster("drag");
          }
        },
        eventDidMount: function (info) {
          info.el.style.margin = "5px";
          if (info.event?.title == "Add Roster") {
            if (info.event?.extendedProps?.count == 0) {
              info.el.style.backgroundColor = "#f9f9f9";
              info.el.style.padding = "55px";
              let title = document.createElement("span");
              let icon = document.createElement("i");
              icon.style.textAlign = "center";
              icon.style.fontSize = "14px";
              icon.style.color = "#A8ADAF";
              icon.innerHTML = `Unallocated`;
              title.appendChild(icon);
              info.el.prepend(title);
              info.el.querySelector(".fc-event-main").style.display =
                "none !important";
            }
          } else {
            let main = info.el.querySelector(".fc-event-main");
            main.style.width = "100%";
            main.style.textAlign = "end";
            info.el.style.padding = "20px 2px";
            let div = document.createElement("div");
            div.style.marginTop = "5px";
            div.style.display = "flex";
            div.style.justifyContent = "space-between";
            div.style.flexDirection = "column";
            div.style.alignItems = "center";
            div.style.width = "100%";
            let div1 = document.createElement("div");

            div1.classList.add("duty");
            div1.innerHTML = info.event.extendedProps.duty
              ? info.event.extendedProps.duty.name
              : "N/A";
            div1.setAttribute("title", "Assigned Job Role");

            let client = document.createElement("div");
            client.classList.add("duty");
            client.innerHTML = info.event.extendedProps.client_company
              ? info.event.extendedProps.client_company
              : "N/A";
            client.setAttribute("title", "Client Company");

            let location = document.createElement("div");
            location.classList.add("duty");
            // add location name and sub location name if sub location is available else add location name only to the event
            location.innerHTML = info.event.extendedProps.location
              ? info.event.extendedProps.location.name
              : "";

            location.setAttribute("title", "Location");

            let edit = document.createElement("div");
            let rosterDate = moment(info.event.startStr).format("YYYY-MM-DD");
            let today = moment(new Date()).format("YYYY-MM-DD");

            // if (JSON.parse(getLogedInUser()).roles[0].name !== "customer") {
            edit.innerHTML = `<button type="button" onclick="window.clickedOn = 'edit'" class="btn btn-sm btn-light text-primary custom">View</button>`;
            if (rosterDate < today) {
              edit.innerHTML = `<button type="button" onclick="window.clickedOn = 'edit'" class="btn btn-sm btn-light text-primary custom">View</button>`;
            } else {
              edit.innerHTML = `<button type="button" onclick="window.clickedOn = 'edit'" class="btn btn-sm btn-light text-primary custom">Edit</button>`;
            }
            div.append(edit);

            if (
              info.event.extendedProps.status === 1 &&
              info.event.extendedProps.e_status === 2
            ) {
              let accept = document.createElement("div");
              accept.innerHTML = `<button type="button" class="btn btn-sm btn-success rounded text-light btn-accept custom">Accepted</button> `;

              div.append(accept);
            }

            if (
              info.event.extendedProps.status === 1 &&
              info.event.extendedProps.e_status === 3
            ) {
              let accept = document.createElement("div");
              accept.innerHTML = `<button type="button" class="btn btn-sm btn-warning rounded text-light btn-accept custom">Declined</button> `;

              div.append(accept);
            }
            // }

            info.el.append(div1, location, div);
          }
        },
        schedulerLicenseKey: "GPL-My-Project-Is-Open-Source",
        resourceAreaWidth: "15%",
        resourceLabelDidMount: function (info) {
          let find = info.el.querySelector(".fc-datagrid-cell-cushion");
          //find.classList.add('labelLane');
          let div2 = document.createElement("span");
          div2.classList.add("fc-datagrid-cell-main");
          div2.classList.add("id");
          let div = document.createElement("span");
          let div3 = document.createElement("span");
          div3.classList.add("fc-datagrid-cell-main");
          div3.classList.add("name");
          let img = document.createElement("img");
          img.style.height = "50px";
          img.style.width = "50px";
          img.style.borderRadius = "50%";
          img.style.border = "1px solid #000";
          img.style.objectFit = "cover";
          img.style.objectPosition = "center";
          div2.innerHTML = "ID" + info.resource.id;
          div3.innerHTML = info.resource.extendedProps.position
            ? info.resource.extendedProps.position
            : "N/A";
          img.src = info.resource.extendedProps.img.includes(
            "https://ui-avatars.com"
          )
            ? info.resource.extendedProps.img
            : store.state.appConfig.imageBaseUrl +
              info.resource.extendedProps.img;
          div.innerHTML = find.prepend(img, div2);
          div.innerHTML = find.append(div3);
          // return { domNodes: [div] };
        },
      },
      // here tuhinjamal added data to integrate repeataion on templete
      untilDate: "",
      days: [
        { text: "Sun", value: "Sunday" },
        { text: "Mon", value: "Monday" },
        { text: "Tue", value: "Tuesday" },
        { text: "Wed", value: "Wednesday" },
        { text: "Thu", value: "Thursday" },
        { text: "Fri", value: "Friday" },
        { text: "Sat", value: "Saturday" },
      ],
      repeat: false,
      repeat_day: null,
      until: null,
      clientPreference: "",
    };
  },
  mounted() {
    this.getDuties();
    
    this.getShifts();
    this.getLocations();

    if (this.logedinUser.roles[0].name === "customer") {
      this.roster.user_id = this.logedinUser?.customer_to_company[0]?.id;
      this.roster.company = this.logedinUser?.customer_to_company[0];

      this.roster.customer = this.logedinUser;
      this.roster.customer_id = this.logedinUser.id;

    } else {
      this.getCompanies();
    }
  },
  methods: {
    showToastNotification(drag) {
      if (drag && drag === "drag") {
        this.$refs.FullCalendar.getApi().refetchEvents();
      }
      toast(
        "Error",
        "AlertTriangleIcon",
        "danger",
        "You can not create roster for past date."
      );
    },
    ValidateDate(date) {
      let rosterDate = moment(date).format("YYYY-MM-DD");
      let today = moment(new Date()).format("YYYY-MM-DD");
      if (rosterDate < today) {
        return false;
      } else {
        return true;
      }
    },
    getLocations() {
      this.$store.dispatch("location/getAllLocations").then((response) => {
        this.locations = response.data;
      });
    },
    getSubLocations(location_id) {
      this.$store
        .dispatch("subLocation/getAllSubLocations", location_id)
        .then((response) => {
          this.subLocations = response.data;
        });
    },
    formateDate(date) {
      // format date to dd-mm-YYYY
      return moment(date).format("DD-MM-YYYY");
    },
    async getDuties() {
      let res = await this.$store.dispatch("duty/getDuties");
      this.duties = res.data.data;
    },
    async getShifts(customer_id) {
      let res = await this.$store.dispatch(
        "shift/getClientShifts",
        customer_id
      );
      this.shifts = res.data;
    },
    setShiftTime(shift) {
      if (shift) {
        this.roster.start_time = shift.start_time;
        this.roster.end_time = shift.end_time;
        this.breaks = shift.breaks;
      }
    },
    async getCompanies() {
      this.$store
        .dispatch("roster/rosterFilter", this.query)
        .then(({ data }) => {
          this.companies = data;
          if (this.companies.length > 0) {
            this.roster.company = this.companies[0];
            this.roster.user_id = this.companies[0].id;
          }
          if (this.logedinUser.roles[0].name === "admin") {
            this.roster.company = this.companies.find(
              (company) => company.id === this.logedinUser.id
            );
            this.roster.user_id = this.logedinUser.id;
          }
          if (this.roster.user_id) {
            this.getCustomers();
          }
        });
    },
    getCustomers() {
      this.query.user_id = this.roster.company?.id;
      this.roster.user_id = this.roster.company?.id;
      this.$store
        .dispatch("roster/rosterFilter", this.query)
        .then((response) => {
          this.customers = response;
          if (this.filterData && this.filterData.customer_id) {
            this.getShifts(this.filterData.customer_id);
          }
        });
    },
    resetModal() {
      this.roster = {
        duty: null,
        shift: null,
        customer: this.logedinUser,
        customer_id: this.logedinUser?.id,
        user_id: this.logedinUser?.customer_to_company[0]?.id,
        company: this.logedinUser?.customer_to_company[0],
        duty_id: "",
        start_time: "",
        end_time: "",
        date: "",
        employee_id: "",
        shift_id: "",
        note: "",
        status: null,
        location: null,
        sub_location: null,
        location_id: "",
        sub_location_id: "",
      };
    },
    async handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();

      this.$refs.simpleRules.validate().then((success) => {
        if (success) {
          this.roster.duty_id = this.roster.duty ? this.roster.duty.id : "";
          // this.roster.shift = this.shifts.length > 0 ? this.shifts[0] : {};
          this.roster.shift_id = this.roster.shift ? this.roster.shift.id : "";
          this.roster.customer_id = this.roster.customer
            ? this.roster.customer.id
            : "";
          this.roster.user_id = this.roster.company
            ? this.roster.company.id
            : JSON.parse(this.$store.state.auth.user).id;
          // eslint-disable-next-line
          this.eventValidate();
        } else {
          toast(
            "Validation Error",
            "AlertTriangleIcon",
            "danger",
            "Please fill all the required fields"
          );
        }
      });
    },
    updateRoster(drag) {
      this.roster.duty_id = this.roster.duty ? this.roster.duty.id : "";
      // this.roster.shift = this.shifts.length > 0 ? this.shifts[0] : {};
      this.roster.shift_id = this.roster.shift ? this.roster.shift.id : "";
      this.roster.customer_id = this.roster.customer
        ? this.roster.customer.id
        : "";
      if (
        this.logedinUser.roles[0].name === "customer" &&
        !this.roster.company
      ) {
        this.roster.company = this.logedinUser.customer_to_company[0];
      }
      this.roster.user_id = this.roster.company
        ? this.roster.company.id
        : JSON.parse(this.$store.state.auth.user).id;
      // eslint-disable-next-line
      this.eventValidate(drag);
    },
    async checkEmployeeAvailability(drag) {
      let dataQuery = {
        employee_id: this.roster.employee_id,
        date: this.roster.date,
      };
      await this.$store
        .dispatch("roster/checkEmployeeAvailability", dataQuery)
        .then((res) => {
          if (res) {
            //  here tuhinjamal starts repeatation section
            this.clientPreference = res.is_roster;
            if (
              res.schedule &&
              res.schedule !== "" && res.schedule.days &&
              res.schedule.days.length > 0
            ) {
              this.repeat = true;
            } else {
              this.repeat = false;
            }
            this.roster.selectedRepeatableDays = res.schedule?.days;
            if (res.schedule?.repeat_day == 0) {
              this.until = 0;
            } else {
              this.until = 1;
            }
            this.roster.until = res.schedule?.until;

            // here tuhinjamal ends repeatation section
            this.employeeAvailable = res.employee ? res.employee : {};
            this.employeeExistingRosters = res.data?.length > 0 ? res.data : [];
            this.passportValidDays = res.days?.days?.passport_days;
            this.drivingValidDays = res.days?.days?.driving_days;
            this.visaValidDays = res.days?.days?.visa_days;
            this.certificateValidDays = res.days?.days?.certificate_days;
            // check if roster date is before today
            if (!this.ValidateDate(this.roster.date)) {
              this.ableToCreateRoster = false;
            }

            // check passport and driving license valid days
            if (this.passportValidDays < 0) {
              this.ableToCreateRoster = false;
              if (drag && drag === "drag") {
                this.$refs.FullCalendar.getApi().refetchEvents();
              }
              toast(
                "Passport is expired thats why you can't create roster for this employee.",
                "AlertTriangleIcon",
                "danger",
                ""
              );
            }
            // else if (
            //   this.drivingValidDays < 0 ||
            //   this.drivingValidDays == null
            // ) {
            //   this.ableToCreateRoster = false;
            //   toast(
            //     "Driving license is expired.",
            //     "AlertTriangleIcon",
            //     "danger",
            //     ""
            //   );
            // }
            else if (this.visaValidDays < 0 || this.visaValidDays == null) {
              this.ableToCreateRoster = false;
              if (drag && drag === "drag") {
                this.$refs.FullCalendar.getApi().refetchEvents();
              }
              toast(
                "Visa is expired thats why you can't create roster for this employee.",
                "AlertTriangleIcon",
                "danger",
                ""
              );
            }
            // else if (this.certificateValidDays.length > 0) {
            //   this.certificateValidDays.forEach((certificate) => {
            //     if (certificate.certificate_rem_days < 0) {
            //       this.ableToCreateRoster = false;
            //       toast(
            //         `${certificate.name} certificate is expired.`,
            //         "AlertTriangleIcon",
            //         "danger",
            //         ""
            //       );
            //     }
            //   });
            // } else {
            //   this.ableToCreateRoster = false;
            //   toast(
            //     "Employee has no certification.",
            //     "AlertTriangleIcon",
            //     "danger",
            //     ""
            //   );
            // }
          } else {
            if (drag && drag === "drag") {
              this.$refs.FullCalendar.getApi().refetchEvents();
            }
            toast(
              "Error",
              "AlertTriangleIcon",
              "danger",
              "Something went wrong."
            );
          }
        });
    },
    eventValidate(drag) {
      let employee_time = {
        start_time: this.employeeAvailable.start_time,
        end_time: this.employeeAvailable.end_time,
      };
      let roster_time = {
        start_time: this.roster.start_time,
        end_time: this.roster.end_time,
        date: this.roster.date,
      };
      if (timeValidatorBetween(roster_time, employee_time)) {
        if (this.employeeExistingRosters.length > 0) {
          // if this.roster is in this.employeeExistingRosters then remove this.roster from this.employeeExistingRosters
          this.employeeExistingRosters = this.employeeExistingRosters.filter(
            (roster) => roster.id !== this.roster.roster_id
          );
          if (validateTimes(this.roster, this.employeeExistingRosters)) {
            if (drag && drag === "drag") {
              this.$refs.FullCalendar.getApi().refetchEvents();
            }
            toast(
              "Employee is not available.",
              "AlertTriangleIcon",
              "danger",
              ""
            );
          } else {
            this.handleSubmit();
          }
        } else {
          this.handleSubmit();
        }
      } else {
        if (drag && drag === "drag") {
          this.$refs.FullCalendar.getApi().refetchEvents();
        }
        toast("Employee is not available.", "AlertTriangleIcon", "danger", "");
      }
    },
    handleSubmit() {
      if (this.clientPreference > 0) {
        this.roster.customer_id = 0;
      }
      let data = {
        duty_id: this.roster.duty_id,
        shift_id: this.roster.shift_id,
        customer_id:
          this.roster.customer_id !== 0 ? this.roster.customer_id : null,
        user_id: this.roster.user_id,
        employee_id: Number(this.roster.employee_id),
        date: this.roster.date,
        location_id: this.roster.location?.id,
        sub_location_id: this.roster.sub_location
          ? this.roster.sub_location.id
          : null,
        status: this.roster.status && this.roster.status.value === 0 ? 0 : 1,
        e_status:
          this.roster.status && this.roster.status.value === 2
            ? 2
            : this.roster.status && this.roster.status.value === 3
            ? 3
            : 0,
        breaks: [],
        times: [
          {
            id: this.roster.roster_id ? this.roster.roster_id : null,
            duty_id: this.roster.duty_id ? this.roster.duty_id : null,
            start_time: this.roster.start_time,
            end_time: this.roster.end_time,
            note: this.roster.note ? this.roster.note : null,
          },
        ],
        // here tuhinjamal added repatation data to pass in parameter
        days: this.roster.selectedRepeatableDays,
        repeat_day: this.until,
        until: this.roster.until,
        schedule: this.repeat,
        // here tuhinjamal ends repatation data to pass in parameter
      };
      if (this.breaks.length > 0) {
        this.breaks.forEach((element, index) => {
          data.breaks.push({
            break_start_time: this.roster.date + " " + element.start_time,
            break_end_time: this.roster.date + " " + element.end_time,
            break_shift_id: element.id,
            id: element?.id,
          });
        });
      }
      this.$store
        .dispatch("roster/createRoster", data)
        .then((res) => {
          this.$bvModal.hide("modal-prevent-closing");
          this.$refs.FullCalendar.getApi().refetchEvents();
          this.resetModal();
          toast(
            "Roster Updated",
            "CheckCircleIcon",
            "success",
            "Roster has been updated successfully"
          );
        })
        .catch((err) => {
          console.log(err);
          toast(
            "Roster Created",
            "CheckCircleIcon",
            "success",
            err.response?.data?.message || "Something went wrong!"
          );
        });
    },
    reCall() {
      this.calendarOptions.events.extraParams.user_id = this.filterData.user_id;
      this.calendarOptions.events.extraParams.customer_id =
        this.filterData.customer_id;
      // this.calendarOptions.events.extraParams.status = this.filterData.status;
      this.calendarOptions.events.extraParams.sector =
        this.filterData.sector_id;
      this.calendarOptions.resources.extraParams.sector =
        this.filterData.sector_id;
      this.calendarOptions.resources.extraParams.user_id =
        this.filterData.user_id;
      this.calendarOptions.resources.extraParams.customer_id =
        this.filterData.customer_id;
      this.calendarOptions.resources.extraParams.employee_id =
        this.filterData.employee_id;
      this.calendarOptions.events.extraParams.employee_id =
        this.filterData.employee_id;
      this.calendarOptions.events.extraParams.e_status = this.filterData
        ?.e_status
        ? this.filterData?.e_status
        : "";
      this.$refs.FullCalendar.getApi().refetchEvents();
      this.$refs.FullCalendar.getApi().refetchResources();
      this.getShifts(this.filterData.customer_id);
    },
    // here tuhinjamal used a function to apply validation on until date
    untilDateValidation(item) {
      var date = new Date().getTime() + 1 * 24 * 60 * 60 * 1000;

      var converted = new Date(date);
      var today =
        converted.getFullYear() +
        "-" +
        (converted.getMonth() + 1) +
        "-" +
        converted.getDate();
      if (item <= today) {
        this.untilDate = "date can not be previous or equal today !";
      } else {
        this.untilDate = "";
      }
    },
    /* here tuhinjamal get client information to get client roster preference on 22-Nov-2023*/
  },
  // watch: {
  //   filterData: {
  //     handler: function (val) {
  //       console.log(val, 'filterData');
  //       // refresh calendar events on filter change with new extra params
  //       this.calendarOptions.events.extraParams.user_id = val.user_id;
  //       this.calendarOptions.events.extraParams.customer_id = val.customer_id;
  //       // this.calendarOptions.events.extraParams.status = val.status;
  //       this.calendarOptions.events.extraParams.sector = val.sector_id;
  //       this.calendarOptions.resources.extraParams.sector = val.sector_id;
  //       this.calendarOptions.resources.extraParams.user_id = val.user_id;
  //       this.calendarOptions.resources.extraParams.customer_id = val.customer_id;
  //       this.$refs.FullCalendar.getApi().refetchEvents();
  //       this.$refs.FullCalendar.getApi().refetchResources();
  //     },
  //     deep: true,
  //   },
  // }
};
</script>
<style scoped>
.fc-license-message {
  display: none;
}
.timeline-event {
  background-color: #f00;
  color: #000;
  border: 1px solid #f00;
  border-radius: 3px;
  padding: 2px 5px;
  font-size: 11px;
  font-weight: bold;
  cursor: pointer;
}
.fc-datagrid-cell-frame:last-child {
  border: 1px solid #bebebe;
}
.fc-timeline-lane-frame {
  border: 1px solid #bebebe;
}

.labelLane {
  align-items: center !important;
  border-radius: 0;
  display: flex !important;
  /*font-size: var(--fc-small-font-size);*/
  margin-bottom: 1px !important;
  padding: 2px 1px !important;
  position: relative !important;
  font-weight: bold;
}

.timeline-event {
  display: flex;
  align-items: center;
}
.fc-timeline-events .fc-event-main i,
.fc-timeline-events .fc-event-main {
  color: #a8adaf !important;
  text-align: center;
  display: none;
}

.fc-timeline-events {
  height: 100% !important;
}

.id {
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  text-align: right;
  color: #79869f;
  padding: 5px;
}
</style>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
