var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{attrs:{"id":"updated-roster"}},[_c('b-card',[_c('FullCalendar',{ref:"FullCalendar",attrs:{"options":_vm.calendarOptions},scopedSlots:_vm._u([{key:"eventContent",fn:function(arg){return [_c('b',[_vm._v(_vm._s(arg.timeText))]),_c('i',[_vm._v(_vm._s(arg.event.title))])]}}])})],1),_c('b-modal',{ref:"modal",attrs:{"id":"modal-prevent-closing","title":_vm.roster.roster_id ? 'Update Roster' : 'Create New Roster',"size":"lg","centered":"","ok-title":_vm.roster.roster_id ? 'Update' : 'Save',"ok-disabled":_vm.ableToCreateRoster === false
        ? true
        : _vm.roster.status == 1 || _vm.roster.roster_id
        ? !_vm.$can('update', 'Roster')
        : !_vm.$can('create', 'Roster')},on:{"show":_vm.resetModal,"hidden":_vm.resetModal,"ok":_vm.handleOk}},[_c('validation-observer',{ref:"simpleRules"},[_c('form',{ref:"form"},[(_vm.employeeAvailable.start_time)?_c('p',{staticClass:"id"},[_vm._v(" This employee is available in between "+_vm._s(new Date( _vm.roster.date + " " + _vm.employeeAvailable.start_time ).toLocaleTimeString())+" to "+_vm._s(new Date( _vm.roster.date + " " + _vm.employeeAvailable.end_time ).toLocaleTimeString())+" on "+_vm._s(_vm.formateDate(_vm.roster.date))+" ")]):_c('p',{staticClass:"id"},[_vm._v(" This Employee is not available on "+_vm._s(_vm.formateDate(_vm.roster.date))+" ")]),(
            _vm.roster.roster_id
              ? _vm.$can('update', 'Roster')
              : _vm.$can('create', 'Roster')
          )?_c('div',{staticClass:"row"},[(_vm.logedinUser.roles[0].name === 'superadmin')?_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Company","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',[_vm._v("Company "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-group',{attrs:{"label-for":"name-input","invalid-feedback":"company is required","state":_vm.nameState}},[_c('v-select',{staticClass:"bg-light",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.companies,"placeholder":"Select company","disabled":""},on:{"input":_vm.getCustomers},model:{value:(_vm.roster.company),callback:function ($$v) {_vm.$set(_vm.roster, "company", $$v)},expression:"roster.company"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1714187096)})],1):_vm._e(),(_vm.logedinUser.roles[0].name === 'superadmin')?_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Client","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',[_vm._v("Client "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-group',{attrs:{"label-for":"name-input","invalid-feedback":"Duty is required","state":_vm.nameState}},[_c('v-select',{staticClass:"bg-light",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.customers,"placeholder":"Select customer","disabled":""},model:{value:(_vm.roster.customer),callback:function ($$v) {_vm.$set(_vm.roster, "customer", $$v)},expression:"roster.customer"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,3090879639)})],1):_vm._e(),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Location","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',[_vm._v("Location "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-group',{attrs:{"label-for":"name-input","invalid-feedback":"Location is required","state":_vm.nameState}},[_c('v-select',{staticClass:"bg-light",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.locations,"placeholder":"Select Location","disabled":_vm.roster.status == 1},on:{"input":function($event){return _vm.getSubLocations(_vm.roster.location.id)}},model:{value:(_vm.roster.location),callback:function ($$v) {_vm.$set(_vm.roster, "location", $$v)},expression:"roster.location"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,2231872087)})],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Sub Location","label-for":"name-input","invalid-feedback":"Sub Location is required","state":_vm.nameState}},[_c('v-select',{staticClass:"bg-light",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.subLocations,"placeholder":"Select Sub Location","disabled":_vm.roster.status == 1},model:{value:(_vm.roster.sub_location),callback:function ($$v) {_vm.$set(_vm.roster, "sub_location", $$v)},expression:"roster.sub_location"}})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Job Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',[_vm._v("Job Role "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-group',{attrs:{"label-for":"name-input","invalid-feedback":"Job Role is required","state":_vm.nameState}},[_c('v-select',{staticClass:"bg-light",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.duties,"placeholder":"Select Job Role"},model:{value:(_vm.roster.duty),callback:function ($$v) {_vm.$set(_vm.roster, "duty", $$v)},expression:"roster.duty"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1216687926)})],1),_c('div',{staticClass:"col-md-6"},[_c('b-form-group',{attrs:{"label":"Shift","label-for":"name-input","invalid-feedback":"Shift is required","state":_vm.nameState}},[_c('v-select',{staticClass:"bg-light",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"name","options":_vm.shifts,"placeholder":"Select Shift"},on:{"input":function($event){return _vm.setShiftTime(_vm.roster.shift)}},model:{value:(_vm.roster.shift),callback:function ($$v) {_vm.$set(_vm.roster, "shift", $$v)},expression:"roster.shift"}})],1)],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"Start Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',[_vm._v("Start Time "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-group',{attrs:{"label-for":"name-input","invalid-feedback":"Start time is required","state":_vm.nameState}},[_c('b-form-timepicker',{staticClass:"bg-light",attrs:{"locale":"en","now-button":"","label-close-button":"Set"},model:{value:(_vm.roster.start_time),callback:function ($$v) {_vm.$set(_vm.roster, "start_time", $$v)},expression:"roster.start_time"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,4140824)})],1),_c('div',{staticClass:"col-md-6"},[_c('validation-provider',{attrs:{"name":"End Time","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',[_vm._v("End Time "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-group',{attrs:{"label-for":"name-input","invalid-feedback":"End time is required","state":_vm.nameState}},[_c('b-form-timepicker',{staticClass:"bg-light",attrs:{"locale":"en","now-button":"","label-close-button":"Set"},model:{value:(_vm.roster.end_time),callback:function ($$v) {_vm.$set(_vm.roster, "end_time", $$v)},expression:"roster.end_time"}})],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1912141047)})],1),_c('div',{staticClass:"col-md-6 mb-5"},[_c('b-form-group',{attrs:{"label":"Change Status","label-for":"name-input"}},[_c('v-select',{staticClass:"bg-light",attrs:{"dir":_vm.$store.state.appConfig.isRTL ? 'rtl' : 'ltr',"label":"text","options":_vm.statuses,"placeholder":"Select status"},model:{value:(_vm.roster.status),callback:function ($$v) {_vm.$set(_vm.roster, "status", $$v)},expression:"roster.status"}})],1)],1),_c('div',{staticClass:"col-md-6 mb-5"},[_c('validation-provider',{attrs:{"name":"Update Note","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('label',[_vm._v("Update Note "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),(_vm.roster.roster_id)?_c('b-form-group',{attrs:{"label-for":"name-input","invalid-feedback":"Update note is required","state":_vm.nameState}},[_c('b-form-textarea',{staticClass:"bg-light",attrs:{"placeholder":"Please enter update note"},model:{value:(_vm.roster.note),callback:function ($$v) {_vm.$set(_vm.roster, "note", $$v)},expression:"roster.note"}})],1):_vm._e(),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1734924726)})],1),_c('div',{staticClass:"col-md-12"},_vm._l((_vm.breaks),function(brk,index){return _c('div',{key:index,staticClass:"row"},[_c('div',{staticClass:"col-4"},[_c('label',[_vm._v("Break Name "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-group',{attrs:{"label-for":"name-input"}},[_c('validation-provider',{attrs:{"name":("Break " + (index + 1) + " Name"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-input',{staticClass:"bg-light data-text",attrs:{"placeholder":"Please enter shift name","disabled":""},model:{value:(brk.name),callback:function ($$v) {_vm.$set(brk, "name", $$v)},expression:"brk.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"col-4"},[_c('label',[_vm._v("Break Start Time "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-group',{attrs:{"label-for":"name-input"}},[_c('validation-provider',{attrs:{"name":("Break " + (index + 1) + " Start Time}"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-timepicker',{staticClass:"bg-light",attrs:{"locale":"en","now-button":"","label-close-button":"Set"},model:{value:(brk.start_time),callback:function ($$v) {_vm.$set(brk, "start_time", $$v)},expression:"brk.start_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('div',{staticClass:"col-4"},[_c('label',[_vm._v("Break End Time "),_c('span',{staticClass:"text-danger"},[_vm._v("*")])]),_c('b-form-group',{attrs:{"label-for":"name-input"}},[_c('validation-provider',{attrs:{"name":("Break " + (index + 1) + " End Time}"),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-timepicker',{staticClass:"bg-light",attrs:{"locale":"en","now-button":"","label-close-button":"Set"},model:{value:(brk.end_time),callback:function ($$v) {_vm.$set(brk, "end_time", $$v)},expression:"brk.end_time"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)])}),0),_c('div',{staticClass:"col-12"},[_c('b-form-checkbox',{attrs:{"id":"repeat","name":"repeat","value":true,"unchecked-value":false},model:{value:(_vm.repeat),callback:function ($$v) {_vm.repeat=$$v},expression:"repeat"}},[_vm._v(" Repeat ")]),(_vm.repeat === true)?_c('div',{staticClass:"card p-2 mt-2"},[_c('b-form-checkbox-group',{attrs:{"id":"checkbox-group-1","options":_vm.days,"name":"flavour-1"},model:{value:(_vm.roster.selectedRepeatableDays),callback:function ($$v) {_vm.$set(_vm.roster, "selectedRepeatableDays", $$v)},expression:"roster.selectedRepeatableDays"}}),_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Repeat Type"}},[_c('div',{staticClass:"d-flex flex-row justify-content-start"},[_c('div',[_c('b-form-radio',{attrs:{"name":"weekly","value":"1"},model:{value:(_vm.until),callback:function ($$v) {_vm.until=$$v},expression:"until"}},[_vm._v("Weekly")])],1),_c('div',{staticClass:"ml-2"},[_c('b-form-radio',{attrs:{"name":"until","value":"0"},model:{value:(_vm.until),callback:function ($$v) {_vm.until=$$v},expression:"until"}},[_vm._v("Until")])],1)]),(_vm.until == 0)?_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6"},[(_vm.until == 0)?_c('validation-provider',{attrs:{"name":"Repeat Until","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
          var errors = ref.errors;
return [_c('b-form-group',{staticClass:"mt-2",attrs:{"label":"Repeat Until"}},[_c('b-form-datepicker',{attrs:{"locale":"en"},on:{"input":function($event){return _vm.untilDateValidation(_vm.roster.until)}},model:{value:(_vm.roster.until),callback:function ($$v) {_vm.$set(_vm.roster, "until", $$v)},expression:"roster.until"}}),(_vm.untilDate != '')?_c('p',{staticClass:"text-danger"},[_vm._v(" "+_vm._s(_vm.untilDate)+" ")]):_vm._e()],1),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,false,1198850524)}):_vm._e()],1)]):_vm._e()])],1):_vm._e()],1)]):_c('div',[(_vm.roster.roster_id)?_c('h4',{staticClass:"my-2"},[_vm._v(" You do not have permission to edit this roster! ")]):_c('h4',{staticClass:"my-2"},[_vm._v(" You do not have permission to create this roster! ")])])])])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }